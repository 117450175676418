// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import filter from 'lodash/filter'
import endsWith from 'lodash/endsWith'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import isInteger from 'lodash/isInteger'
import noop from 'lodash/noop'
import head from 'lodash/head'
import last from 'lodash/last'
import isNull from 'lodash/isNull'

import classNames from 'classnames'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { navigate } from 'gatsby-plugin-intl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Footer */
class Footer extends React.PureComponent {
  /** [constructor description] */
  constructor(props) {
    super(props)

    const { uri, edgesData } = this.props
    const {
      allResources: { edges },
    } = edgesData

    const nodes = map(edges, 'node')
    const ordered = orderBy(nodes, ['position', 'asc'])
    const nodePositions = []
    map(groupBy(map(ordered, 'position'), Math.floor), (a) =>
      map(a, (v) => (isInteger(v) ? nodePositions.push(v) : noop()))
    )

    this.state = {
      ordered,
      nodePositions,
    }

    this.leftFx = this.leftFx.bind(this)
    this.rightFx = this.rightFx.bind(this)
  }

  /** [componentDidMount description] */
  componentDidMount() {
    document.addEventListener('keydown', this.leftFx, false)
    document.addEventListener('keydown', this.rightFx, false)
  }

  /** [componentWillUnmount description] */
  componentWillUnmount() {
    document.addEventListener('keydown', this.leftFx, false)
    document.addEventListener('keydown', this.rightFx, false)
  }

  /** [leftFx description] */
  leftFx(event) {
    if (event.keyCode === 37) {
      const { uri, edgesData } = this.props
      const { nodePositions } = this.state
      const {
        allResources: { edges },
      } = edgesData

      const match = filter(edges, ({ node }) => endsWith(uri, node.routeSlug))
      const currentNodePosition =
        match.length > 0 ? Math.floor(match[0].node.position) : -1
      const prev =
        match.length > 0
          ? head(nodePositions) !== currentNodePosition
            ? filter(
                edges,
                ({ node }) => node.position === currentNodePosition - 1
              )[0].node
            : null
          : null

      if (isNull(prev) === false) {
        navigate(prev.routeSlug)
      }
    }
  }

  /** [rightFx description] */
  rightFx(event) {
    if (event.keyCode === 39) {
      const { uri, edgesData } = this.props
      const { nodePositions } = this.state
      const {
        allResources: { edges },
      } = edgesData

      const match = filter(edges, ({ node }) => endsWith(uri, node.routeSlug))
      const currentNodePosition =
        match.length > 0 ? Math.floor(match[0].node.position) : -1
      const next =
        match.length > 0
          ? last(nodePositions) !== currentNodePosition
            ? filter(
                edges,
                ({ node }) => node.position === currentNodePosition + 1
              )[0].node
            : null
          : null

      if (isNull(next) === false) {
        navigate(next.routeSlug)
      }
    }
  }

  /** [render description] */
  render() {
    const { uri, edgesData } = this.props
    const { nodePositions } = this.state
    const {
      allResources: { edges },
    } = edgesData

    const match = filter(edges, ({ node }) => endsWith(uri, node.routeSlug))
    const currentNodePosition =
      match.length > 0 ? Math.floor(match[0].node.position) : -1
    const prev =
      match.length > 0
        ? head(nodePositions) !== currentNodePosition
          ? filter(
              edges,
              ({ node }) => node.position === currentNodePosition - 1
            )[0].node
          : null
        : null
    const next =
      match.length > 0
        ? last(nodePositions) !== currentNodePosition
          ? filter(
              edges,
              ({ node }) => node.position === currentNodePosition + 1
            )[0].node
          : null
        : null

    const altMatch =
      endsWith(uri, 'en') ||
      endsWith(uri, 'en/') ||
      endsWith(uri, 'about') ||
      endsWith(uri, 'about/') ||
      endsWith(uri, 'impressum') ||
      endsWith(uri, 'impressum/') ||
      endsWith(uri, 'contribute') ||
      endsWith(uri, 'contribute/') ||
      endsWith(uri, 'copyright') ||
      endsWith(uri, 'copyright/')

    const coverFooter = endsWith(uri, 'en') || endsWith(uri, 'en/')

    return (
      <Fragment>
        {match.length > 0 && (
          <Fragment>
            <footer
              className={classNames('container', {
                'cover-footer': coverFooter,
              })}
            >
              <div className="prev-next">
                {head(nodePositions) !== currentNodePosition ? (
                  <Link
                    to={prev.routeSlug}
                    className="prev"
                    aria-label="Previous"
                  >
                    <span>← {prev.title}</span>
                  </Link>
                ) : (
                  <Link to="/" className="prev" aria-label="Previous">
                    <span>← Home</span>
                  </Link>
                )}
                {head(nodePositions) !== currentNodePosition && (
                  <Link to="/" className="top" aria-label="Previous">
                    <span>Home</span>
                  </Link>
                )}
                <Link
                  to={match[0].node.routeSlug}
                  className="chapter"
                  aria-label="Top"
                >
                  <span>Top</span>
                </Link>
                {last(nodePositions) !== currentNodePosition ? (
                  <Link to={next.routeSlug} className="next" aria-label="Next">
                    <span>{next.title} →</span>
                  </Link>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
              <div className="copyright-notices">
                <p>
                  <span>©</span>&nbsp;
                  <Link to="https://books.prisma.haus/en/">
                    <span>Prisma Books</span>
                  </Link>
                  <br />
                  <Link to="/about#on-gift-economy">
                    <span>On gift economy & reader supported</span>
                  </Link>
                </p>
                <p>
                  <span>Published by </span>
                  <Link to="https://books.prisma.haus/en/">
                    <span>Prisma Books</span>
                  </Link>
                  <br />
                  <span>Handcrafted by </span>
                  <Link to="https://design.prisma.haus/en/">
                    <span>Prisma Design</span>
                  </Link>
                </p>
              </div>
            </footer>
          </Fragment>
        )}
        {altMatch === true && (
          <footer
            className={classNames('container', {
              'cover-footer': coverFooter,
            })}
          >
            <div className="prev-next">
              <Link to="/" className="prev" aria-label="Previous">
                <span>← Home</span>
              </Link>
              <Link to="/a-dream" className="next" aria-label="Next">
                <span>A Dream →</span>
              </Link>
            </div>
            <div className="copyright-notices">
              <p>
                <span>©</span>{' '}
                <Link to="https://books.prisma.haus/en/">
                  <span>Prisma Books</span>
                </Link>
                <br />
                <Link to="/about#on-gift-economy">
                  <span>On gift economy & reader supported</span>
                </Link>
              </p>
              <p>
                <span>Published by </span>
                <Link to="https://books.prisma.haus/en/">
                  <span>Prisma Books</span>
                </Link>
                <br />
                <span>Handcrafted by </span>
                <Link to="https://design.prisma.haus/en/">
                  <span>Prisma Design</span>
                </Link>
              </p>
            </div>
          </footer>
        )}
      </Fragment>
    )
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Footer
