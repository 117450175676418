// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import takeRight from 'lodash/takeRight'
import matches from 'lodash/matches'
import replace from 'lodash/replace'
import endsWith from 'lodash/endsWith'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import makeTree from './makeTree'

import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources {
      edges {
        node {
          title
          position
          routeSlug
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** TableOfContents */
const TableOfContents = ({ uri, pageContext, expanded, expand }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const {
        allResources: { edges },
      } = data

      const nodes = map(edges, 'node')
      const nodes2 = filter(nodes, (o) => o.position < 9)
      const ordered = orderBy(nodes2, ['position', 'asc'])
      const nodePositions = groupBy(map(ordered, 'position'), Math.floor)

      const treeData = {
        title: 'Aims and Ideals of Auroville',
        key: '0-0-0',
        children: [],
      }
      map(nodePositions, (nodePosition, index) => {
        const hasChildren = nodePosition.length > 1
        const first = head(nodePosition, 1)
        const children =
          hasChildren === true
            ? takeRight(nodePosition, nodePosition.length - 1)
            : []
        const parent = filter(ordered, matches({ position: first }))[0]
        const treeNode = {
          title: `${index}. ${parent.title}`,
          key: `0-${replace(parent.position, '.', '-')}-0`,
          children: [],
          routeSlug: parent.routeSlug,
        }
        const parentIndex = index

        map(children, (child, index) => {
          const node = filter(ordered, matches({ position: child }))[0]

          treeNode.children.push({
            title: `${parentIndex}.${index + 1}. ${node.title}`,
            key: `0-${replace(node.position, '.', '-')}`,
            routeSlug: `${parent.routeSlug}#${node.routeSlug}`,
          })
        })

        treeData.children.push(treeNode)
      })

      return (
        <Fragment>
          <nav>
            <li>
              <Link to="/">
                <small>{treeData.title}</small>
              </Link>
            </li>
            {makeTree(treeData.children, uri, pageContext, expanded, expand)}
          </nav>
          <div className="etc">
            <Link to="/about" style={{ display: 'block', marginBottom: 0 }}>
              <small>About</small>
            </Link>
            <Link
              to="/contribute"
              style={{ display: 'block', marginBottom: 0 }}
            >
              <small>Contribute</small>
            </Link>
            <Link to="/impressum" style={{ display: 'block', marginBottom: 0 }}>
              <small>Impressum</small>
            </Link>
            <Link to="/copyright" style={{ display: 'block', marginBottom: 0 }}>
              <small>Copyright</small>
            </Link>
          </div>
        </Fragment>
      )
    }}
  />
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default TableOfContents
