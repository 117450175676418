// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import compose from 'recompose/compose'
// import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Spring, animated } from 'react-spring'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from '../table-of-contents'
import '../table-of-contents/style.less'

import Cross from '../svg/cross'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Slider */
class Slider extends React.PureComponent {
  /** [constructor description] */
  constructor() {
    super()

    this.wrapperRef = React.createRef()
    this.escFunction = this.escFunction.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  /** [componentDidMount description] */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('keydown', this.escFunction, false)
  }

  /** [componentWillUnmount description] */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('keydown', this.escFunction, false)
  }

  /** [handleClickOutside description] */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      const { linkRef } = this.props

      if (linkRef && !linkRef.current.contains(event.target)) {
        const { isActive, update } = this.props
        if (isActive === true) {
          update(false, 'root')
        }
      }
    }
  }

  /** [escFunction description] */
  escFunction(event) {
    if (event.keyCode === 27) {
      const { isActive, update } = this.props
      if (isActive === true) {
        update(false, 'root')
      }
    }
  }

  /** [render description] */
  render() {
    const { isActive, whatsActive, update } = this.props

    return (
      <Spring
        native
        reset
        reverse={!isActive}
        from={{ height: '0vh' }}
        to={{
          height:
            whatsActive === 'root'
              ? '0vh'
              : whatsActive === 'options'
              ? '60vh'
              : '80vh',
        }}
      >
        {(props) => (
          <animated.div
            ref={this.wrapperRef}
            className={`slider ${isActive === true ? 'active' : 'passive'}`}
            style={props}
          >
            {whatsActive === 'table-of-contents' && (
              <TableOfContents update={update} />
            )}
            <div
              className="close"
              role="button"
              aria-pressed="false"
              tabIndex="0"
              onClick={() => update(false, 'root')}
            >
              <Cross />
            </div>
          </animated.div>
        )}
      </Spring>
    )
  }
}

// // ----------------------------------------------------------------------------
// // ---------------------------------------------------------------------- State
// // ----------------------------------------------------------------------------
// const withState = connect(
//   state => ({
//     headerState: state.headerState,
//     mediaState: state.mediaState,
//   }),
//   dispatch => ({
//     updateHeaderState(payload) {
//       dispatch(updateHeaderState(payload))
//     },
//   })
// )

// // ----------------------------------------------------------------------------
// // -------------------------------------------------------------------- Compose
// // ----------------------------------------------------------------------------
// /** Compose ala FP style */
// const ComposedHeader = compose(
//   withState // Add state
// )(Header)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Slider
